export const environment = {
    production: false,
    version: '0.0.1',
    backendUri: 'https://lam-pro-backend-canada-dev.azurewebsites.net',
    appUri: 'https://dev-app.lifeafterme.ca',
    proDomain: 'dev.lifeafterme.ca',
    caDomain: 'dev.lifeafterme.ca',
    proAppUri: 'https://dev-app.lifeafterme.ca',
    supportEmail: 'support@lifeafterme.ca',
    allowGTM: true,
    region: 'CA'
  };
  